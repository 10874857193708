import React, { useState } from 'react'

const defaultState = {
  cartItems: () => ([])
}

export const CartItemsContext = React.createContext(defaultState)

export const CartItemsProvider = (props) => {
  const [cartItems, setCartItems] = useState([])

  // useEffect(() => {
  //   if(typeof window !== 'undefined'){
  //     if (localStorage.getItem("menui-order") !== null) {
  //       let localCart = localStorage.getItem("menui-order");
  //       setCartItems(JSON.parse(localCart))
  //     }else{
  //       localStorage.setItem("menui-order", JSON.stringify(cartItems))
  //     }
  //   }
  // }, [props])

  return (
    <CartItemsContext.Provider value={[cartItems, setCartItems]}>
      {props.children}
    </CartItemsContext.Provider>
  )
}

// export default CartItemsContext
