import React, { useState, useEffect } from 'react'
import api from '../components/utils/api'

const defaultState = {
  settings: () => ('')
}
export const SettingsContext = React.createContext(defaultState)

export const SettingsProvider = (props) => {
  const [settings, setSettings] = useState([])
  const [isSecure, setIsSecure] = useState(false)

  /********************
  * SECURITY CHECK
  *********************/ 
  useEffect(() => {
    try {
      fetch(`https://api.ipregistry.co/?key=${process.env.GATSBY_IP_REGISTRY_KEY}`)
        .then((res) => {
          return res.json()
          // console.log(res.json())
        }).then((payload) => {

          const isAbuser = payload['security']['is_abuser']
          const isAttacker = payload['security']['is_attacker']
          const isBogon = payload['security']['is_bogon']
          // const isCloudProvider = payload['security']['is_cloud_provider']
          // const isProxy = payload['security']['is_proxy']
          // const isRelay = payload['security']['is_relay']
          // const isTor = payload['security']['is_tor']
          // const isTorExit = payload['security']['is_tor_exit']
          // const isAnonymous = payload['security']['is_anonymous']
          // const isVpn = payload['security']['is_vpn']
          const isThreat = payload['security']['is_threat']
          // console.log(isVpn)
          
          if (isAbuser || isAttacker || isBogon || isThreat) {
              setIsSecure(false)
          } else{
            setIsSecure(true)
          }

        })
    } catch (error) {
      console.log(error)
    }
  }, [])
  /********************
   * SECURITY CHECK END
  *********************/ 


  useEffect(() => {
    if(!settings.length && isSecure) {
      // console.log('Settings loaded')
      api.readAllSettings().then((settings) => {
          if(settings && settings.length) { 
              setSettings(settings) 
          }
      })
    }
  }, [isSecure])

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {props.children}
    </SettingsContext.Provider>
  )
}
