/* Api methods */

// const create = (data) => {
//   return fetch('/.netlify/functions/todos-create', {
//     body: JSON.stringify(data),
//     method: 'POST'
//   }).then(response => {
//     return response.json()
//   })
// }

const readArticleDetails = (articleId) => {
  return fetch(`/.netlify/functions/article-details-read/${articleId}`).then((response) => {
    return response.json()
  })
}

const createTakeAwayOrder = (data) => {
  return fetch('/.netlify/functions/takeaway-order-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const ringStaff = (data) => {
  return fetch('/.netlify/functions/ring-staff', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createDeliveryOrder = (data) => {
  return fetch('/.netlify/functions/delivery-order-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createLocallyOrder = (data) => {
  return fetch('/.netlify/functions/locally-order-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createReservation = (data) => {
  return fetch('/.netlify/functions/reservation-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const createCustomer = (data) => {
  return fetch('/.netlify/functions/customer-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const readTakeAwayTimes = () => {
  return fetch('/.netlify/functions/read-takeawaytimes').then((response) => {
    return response.json()
  })
}

const readReservedBookings = (date) => {
  return fetch(`/.netlify/functions/read-reserved-bookings/${date}`).then((response) => {
    return response.json()
  })
}

const readDeliveryTimes = () => {
  return fetch('/.netlify/functions/read-deliverytimes').then((response) => {
    return response.json()
  })
}

const readBookingTimes = () => {
  return fetch('/.netlify/functions/read-bookingtimes').then((response) => {
    return response.json()
  })
}

const readDeliverySettings = () => {
  return fetch('/.netlify/functions/read-deliverysettings').then((response) => {
    return response.json()
  })
}

const readDisabledDeliveryDays = () => {
  return fetch('/.netlify/functions/read-disabled-delivery-days').then((response) => {
    return response.json()
  })
}

const readDisabledBookingDays = () => {
  return fetch('/.netlify/functions/read-disabled-booking-days').then((response) => {
    return response.json()
  })
}

const readDisabledTakeAwayDays = () => {
  return fetch('/.netlify/functions/read-disabled-takeaway-days').then((response) => {
    return response.json()
  })
}


// const readAll = () => {
//   return fetch('/.netlify/functions/todos-read-all').then((response) => {
//     return response.json()
//   })
// }

const readAllArticles = () => {
  return fetch('/.netlify/functions/articles-read-all').then((response) => {
    return response.json()
  })
}

const searchArticlesByLanguage = (lang) => {
  return fetch(`/.netlify/functions/articles-by-language-search/${lang}`).then((response) => {
    return response.json()
  })
}

const readArticleSearch = (keyword) => {
  return fetch(`/.netlify/functions/article-search/${keyword}`).then((response) => {
    return response.json()
  })
}

const checkCustomer = (keyword) => {
  return fetch(`/.netlify/functions/customer-check/${keyword}`).then((response) => {
    return response.json()
  })
}

const readCategoryArticles = (keyword) => {
  return fetch(`/.netlify/functions/category-articles/${keyword}`).then((response) => {
    return response.json()
  })
}

const readSubcategoryDescription = (subcategory) => {
  return fetch(`/.netlify/functions/subcategory-description/${subcategory}`).then((response) => {
    return response.json()
  })
}

// const readAllCategories = () => {
//   return fetch('/.netlify/functions/categories-read-all').then((response) => {
//     return response.json()
//   })
// }

const readCategoriesByLanguage = (lang) => {
  return fetch(`/.netlify/functions/categories-by-language-read/${lang}`).then((response) => {
    return response.json()
  })
}


const readAllSettings = () => {
  return fetch('/.netlify/functions/settings-read-all').then((response) => {
    return response.json()
  })
}

const readAllBookings = () => {
  return fetch('/.netlify/functions/bookings-read-all').then((response) => {
    return response.json()
  })
}

const checkTable = (keyword) => {
  return fetch(`/.netlify/functions/table-check/${keyword}`).then((response) => {
    return response.json()
  })
}

// const readAllTakeAwayOrders = () => {
//   return fetch('/.netlify/functions/takeaway-orders-read-all').then((response) => {
//     return response.json()
//   })
// }


// const update = (todoId, data) => {
//   return fetch(`/.netlify/functions/todos-update/${todoId}`, {
//     body: JSON.stringify(data),
//     method: 'POST'
//   }).then(response => {
//     return response.json()
//   })
// }

const updateBooking = (bookingId, data) => {
  return fetch(`/.netlify/functions/bookings-update/${bookingId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateLocallyOrder = (locallyOrderId, data) => {
  return fetch(`/.netlify/functions/locallyorders-update/${locallyOrderId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateTakeAwayOrder = (takeAwayOrderId, data) => {
  return fetch(`/.netlify/functions/takeawayorders-update/${takeAwayOrderId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const updateDeliveryOrder = (deliveryOrderId, data) => {
  return fetch(`/.netlify/functions/deliveryorders-update/${deliveryOrderId}`, {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

// const deleteTodo = (todoId) => {
//   return fetch(`/.netlify/functions/todos-delete/${todoId}`, {
//     method: 'POST',
//   }).then(response => {
//     return response.json()
//   })
// }


const deleteBooking = (bookingId) => {
  return fetch(`/.netlify/functions/bookings-delete/${bookingId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

// const batchDeleteTodo = (todoIds) => {
//   return fetch(`/.netlify/functions/todos-delete-batch`, {
//     body: JSON.stringify({
//       ids: todoIds
//     }),
//     method: 'POST'
//   }).then(response => {
//     return response.json()
//   })
// }

const batchDeleteBooking = (bookingIds) => {
  return fetch(`/.netlify/functions/bookings-delete-batch`, {
    body: JSON.stringify({
      ids: bookingIds
    }),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const deleteTakeAwayOrder = (takeAwayOrderId) => {
  return fetch(`/.netlify/functions/takeawayorders-delete/${takeAwayOrderId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

const deleteDeliveryOrder = (deliveryOrderId) => {
  return fetch(`/.netlify/functions/deliveryorders-delete/${deliveryOrderId}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}


export default {
  readArticleDetails: readArticleDetails,
  searchArticlesByLanguage: searchArticlesByLanguage,
  createTakeAwayOrder: createTakeAwayOrder,
  createDeliveryOrder: createDeliveryOrder,
  createLocallyOrder: createLocallyOrder,
  createReservation: createReservation,
  createCustomer: createCustomer,
  readTakeAwayTimes: readTakeAwayTimes,
  readDeliveryTimes: readDeliveryTimes,
  readBookingTimes: readBookingTimes,
  readDeliverySettings: readDeliverySettings,
  readDisabledDeliveryDays: readDisabledDeliveryDays,
  readDisabledTakeAwayDays: readDisabledTakeAwayDays,
  readDisabledBookingDays: readDisabledBookingDays,
  readAllArticles: readAllArticles,
  readArticleSearch: readArticleSearch,
  readCategoryArticles: readCategoryArticles,
  readSubcategoryDescription: readSubcategoryDescription,
  readCategoriesByLanguage: readCategoriesByLanguage,
  // readAllCategoryFavorites: readAllCategoryFavorites,
  readAllSettings: readAllSettings,
  readReservedBookings: readReservedBookings,
  // readAll: readAll,
  readAllBookings: readAllBookings,
  // update: update,
  updateBooking: updateBooking,
  updateLocallyOrder: updateLocallyOrder,
  updateTakeAwayOrder: updateTakeAwayOrder,
  updateDeliveryOrder: updateDeliveryOrder,
  // delete: deleteTodo,
  deleteBooking: deleteBooking,
  deleteTakeAwayOrder: deleteTakeAwayOrder,
  deleteDeliveryOrder: deleteDeliveryOrder,
  // batchDelete: batchDeleteTodo,
  batchDeleteBooking: batchDeleteBooking,
  ringStaff: ringStaff,
  checkCustomer: checkCustomer,
  checkTable: checkTable
}
